.as-contact-container__row {
  margin: 0 -10px;
  
  @include respond-to(tablet) {
    flex-wrap: wrap;
    margin: 0 auto;
  }
}

.as-contact-container__item {
  position: relative;
  margin-bottom: 15px;
}

.as-contact-container__cell {
  @include respond-to(tablet) {
    margin: 0 0 20px;
  }
}

.as-contact-container__img {
  display: block;
  width: 100%;
  margin: 30px 0;
  border-bottom: 1px solid $as-c_main;
}

.as-contact-container-details__row {
  margin: 20px 0;

  > a {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    color: #333;

    &:hover {
      text-decoration: underline;
    }
  }

  > i {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.3em;
    color: $as-c_main;
  }
}