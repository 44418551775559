.as-inside-page {
  padding-top: 130px;

  @include respond-to(medium-screens) {
    padding-top: 80px;
  }

  @include respond-to(tablet) {
    padding-top: 70px;
  }
}