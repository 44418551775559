* {
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
				
	&:focus {
		outline: none;
	}
}

html, body {
	direction: rtl;
	position: relative;
	min-height: 100vh;
	background: #fff;
	font-family: $as-font_family;
	-webkit-font-smoothing: antialiased;
	color: $as-c_font;
			
	b, strong, th, legend {
		font-weight: 700;
	}
			
	p {
		a {
			color: $as-c_href;
			
			&:hover, &:focus {
				color: darken($as-c_href, 25%);
				text-decoration: underline;
			}
		}
	}
			
	a {
	  cursor: pointer;
	  text-decoration: none;
	}
			
	input, textarea, select {
	  box-sizing: border-box;
		padding: 5px;
		font-family: $as-font_family;
		color: $as-c_font;
	  outline: none;
		box-shadow: none;
		border-radius: 0;
		border: 1px solid #333;
		-webkit-appearance: none;
	}

	button {
		font-family: $as-font_family;
	}
			
	fieldset {
	  border: 1px solid $as-c_line;
	  margin: 0;
	  padding: 15px 20px;
	}

	img {
		display: block;
	}
			
	.as-hidden {
	  display: none !important;
	  visibility: hidden !important;
	}

	.as-blur {
		filter: blur(3px);
	}

	.as-disable-scroll {
		overflow-x: hidden;
		overflow-y: scroll !important;
	}

	.as-scroll {
		&::-webkit-scrollbar {
			width: 7px;
			height: 7px;
		}
		
		&::-webkit-scrollbar-track {
			background: #f7f7f7;
		}
		
		&::-webkit-scrollbar-thumb {
			background: #d4d4d4;
			border-radius: 7px;
		}
	}
}