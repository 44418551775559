.as-courses-group-list {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}

.as-courses-group-list__item {
  width: 33.333%;
  padding: 15px;
  text-align: center;

  @include respond-to(tablet) {
    width: 50%;
  }

  @include respond-to(small-tablet) {
    width: 100%;
  }
}

.as-courses-group-list-item {
  display: block;
  padding: 10px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
}

.as-courses-group-list-item__img-wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 75%;
}

.as-courses-group-list-item__img {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translate(-50%, 0);
}

.as-courses-group-list-item__title {
  margin: 10px 0;
  font-size: 1.6em;
  font-weight: 500;
  color: $as-c_main;
  min-height: 58px;
}

.as-courses-group-list-item__description {
  margin: 0 0 10px;
  font-size: 0.95em;
  min-height: 68px;
}

.as-courses-group-list-item__btn {
  width: calc(100% - 20px);
}