.as-btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: none;
  text-align: center;
  font-size: 1.1em;
  padding: 10px 35px;
  color: #333;
  background: #ddd;
  -webkit-appearance: none;
  @include skew-background;

  &:hover, &:focus {
    background: #CFCFCF;
  }
}

.as-btn_primary {
  @extend .as-btn;
  color: $as-c_sub;
  background: $as-c_main;
  transition: ease .1s;
  @include skew-background;

  &:before, &:after {
    transition: ease .1s;    
  }

  &:hover, &:focus {
    background: darken($as-c_main, 5%);
  }
}

.as-btn__icon {
  font-size: 1em;
  vertical-align: middle;
}

.billy-active-button{
  top: 19% !important;
}