.as-newsletter-box {
  width: 100%;
  padding: 10px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);

  .as-form__checkbox-wrap {
    font-size: 0.9em;
  }
}

.as-newsletter-box__title {
  margin: 0 0 5px;
  font-size: 1.6em;
  font-weight: 500;
  color: $as-c_main;
}

.as-newsletter-box__submit {
  display: block;
  margin: 0 auto;
  width: calc(100% - 20px);
}