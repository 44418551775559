.as-courses-form-panel {
  padding: 20px 0;

  @include respond-to(small-screens) {
    .as-form__row_margin_center {
      flex-wrap: wrap;
    }

    .as-form__cell, 
    .as-form__cell_grow, 
    .as-form__cell_nowrap {
      width: 100%;
      margin-bottom: 10px;
    }
    
    .as-form__submit {
      display: block;
      margin: 0 auto;
    }
  }
}

.as-courses-form-panel__label {
  font-size: 1.3em;
  font-weight: 500;
  color: #5E5E5E;
}