@include font-face(Flaticon, assets/fonts/Flaticon/Flaticon, normal);

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("assets/fonts/Flaticon/Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.fi-menu:before { content: "\f100"; }
.fi-up:before { content: "\f101"; }
.fi-place:before { content: "\f102"; }
.fi-phone:before { content: "\f103"; }
.fi-upload:before { content: "\f104"; }
.fi-youtube:before { content: "\f105"; }
.fi-in:before { content: "\f106"; }
.fi-pause:before { content: "\f107"; }
.fi-envelope:before { content: "\f108"; }
.fi-facebook:before { content: "\f109"; }
.fi-instagram:before { content: "\f10a"; }
.fi-google:before { content: "\f10b"; }
.fi-right:before { content: "\f10c"; }
.fi-left:before { content: "\f10d"; }
.fi-search:before { content: "\f10e"; }
.fi-play-button:before { content: "\f10f"; }
.fi-down:before { content: "\f110"; }
.fi-twitter:before { content: "\f111"; }
    
$fi-menu: "\f100";
$fi-up: "\f101";
$fi-place: "\f102";
$fi-phone: "\f103";
$fi-upload: "\f104";
$fi-youtube: "\f105";
$fi-in: "\f106";
$fi-pause: "\f107";
$fi-envelope: "\f108";
$fi-facebook: "\f109";
$fi-instagram: "\f10a";
$fi-google: "\f10b";
$fi-right: "\f10c";
$fi-left: "\f10d";
$fi-search: "\f10e";
$fi-play-button: "\f10f";
$fi-down: "\f110";
$fi-twitter: "\f111";