.as-news-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.as-news-list__item {
  border-bottom: 2px solid $as-c_main;

  &:last-child {
    border-bottom: none;
  }
}

.as-news-list-item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 30px 0;  
  min-height: 175px;
  color: $as-c_font;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: ease .2s;

  @include respond-to(small-tablet) {
    flex-wrap: wrap;
  }

  &:hover, &:focus {
    .as-news-list-item__image {
      filter: contrast(130%);
      transform: scale(1.06);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, .4);
    }
  }
}

.as-news-list-item__image {
  position: relative;
  width: 250px;
  transition: ease .2s;

  @include respond-to(medium-screens) {
    width: 150px;
  }

  @include respond-to(small-tablet) {
    width: 100%;
  }
}

.as-news-list-item__content {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-content: flex-start;
  align-self: stretch;
  padding: 0 20px;
  width: 100%;

  @include respond-to(small-tablet) {
    padding: 10px 0 0;
  }
}

.as-news-list-item__title {
  margin: 0;
  font-size: 1.8em;
  font-weight: 500;
  color: $as-c_main;

  @include respond-to(handhelds) {
    font-size: 1.6em;
  }
}

.as-news-list-item__date {
  display: inline-block;
  margin: 0 5px;
  padding: 0 10px;
  font-size: 0.6em;
  font-weight: 400;
  color: #6E6E6E;
  border-right: 2px solid #A1A1A1;
}

.as-news-list-item__subtitle {
  margin: 0;
  font-size: 1.3em;
  font-weight: 400;
  color: #6E6E6E;

  @include respond-to(handhelds) {
    font-size: 1.1em;
  }
}

.as-news-list-item__description {
  font-size: 1em;
  line-height: 1.4em;
}