.as-contact-form {
  .as-form__cell_nowrap {
    min-width: 100px;
  }

  @include respond-to(medium-screens) {
    .as-contact-form__cell {
      margin-top: 15px;
    }
  }

  @include respond-to(tablet) {
    .as-form__row, .as-form__row_margin, .as-form__row_margin_start {
      flex-wrap: wrap;
    }
  }
}