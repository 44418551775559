.as-header {
  z-index: 9;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  background: $as-bg_main;

  @include respond-to(medium-screens) {
    position: fixed;
  }
}

.as-header-wrapper {
  overflow: hidden;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  height: $as-g__header-height;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  @include respond-to(medium-screens) {
    background: #fff;
    background-image: none !important;
    height: $as-g__header-height_mobile;
  }
}

.as-header-logo-wrap {
  display: flex;
  align-items: center;
  position: relative;
  right: -25px;
  padding: 0 50px 0 35px;
  background: #1D2633;
  @include skew-background;

  @include respond-to(medium-screens) {
    background: #fff;
  }
}

.as-header-logo {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;

  @include respond-to(medium-screens) {
    max-width: 200px;
  }
}

.as-header-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  left: -25px;
  padding: 0 35px 0 50px;
  text-align: left;
  background: #1D2633;
  @include skew-background;

  @include respond-to(medium-screens) {
    display: none;
  }
}

.as-header-details__row {
  margin-bottom: 5px;
  color: #fff;

  > a {
    color: #fff;
  }
}

.as-header__menu-wrapper {
  background: #fff;

  @include respond-to(medium-screens) {
    .as-g__wrapper {
      height: 0;
    }
  }
}

.as-header-menu-open-btn {
  align-self: center;
  cursor: pointer;
  color: $as-c_main;
  background: transparent;
  padding: 5px 20px;
  border: none;
  font-size: 2.3em;
}