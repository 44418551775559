.as-gallery {
  display: flex;
  margin: 0 -10px;

  @include respond-to(handhelds) {
    flex-wrap: wrap;
  }
}

.as-gallery-menu {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  width: 200px;

  @include respond-to(handhelds) {
    position: relative;
    overflow-y: auto;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 10px;
    margin: 0 10px 10px;
    border: none;
  
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
  
    &::-webkit-scrollbar-track {
      background: rgba($as-c_main, 0.2);
    }
  
    &::-webkit-scrollbar-thumb {
      background: $as-c_main;
      border-radius: 4px;
    }
  }
}

.as-gallery-menu__item {
  margin-bottom: 10px;

  @include respond-to(handhelds) {
    margin: 0 5px;
  }
}

.as-gallery-menu__btn {
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  color: $as-c_main;
  background: transparent;
  transition: ease .15s;

  &:hover {
    color: darken($as-c_main, 5%);
    background: rgba($as-c_main, 0.2);
  }

  &.as-active {
    color: #fff;
    background: $as-c_main;
  }

  @include respond-to(handhelds) {
    padding: 8px 20px;
  }
}

.as-gallery-container {
  display: flex;
  align-content: flex-start;
  flex-grow: 1;
  margin: 0 10px;
  flex-wrap: wrap;
  width: 100%;
  margin: -4px 10px;
}

.as-gallery-container__item {
  width: 25%;
  padding: 4px;

  @include respond-to(small-screens) {
    width: 33.333%;
  }

  @include respond-to(tablet) {
    width: 50%;
  }
}

.as-gallery-container__img-wrap {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  transition: ease .25s;

  &:after {
    content: '+';
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 3em;
    color: #fff;
    background: transparent;
    transform: scale(0.1);
    transition: ease .25s;
  }

  &:hover {
    transform: scale(0.95);
    &:after {
      opacity: 1;
      background: rgba($as-c_main, 0.6);
      transform: scale(1);
    }
  }
}

.as-gallery-container__img {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.as-gallery-preview {
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: ease .2s;

  &.as-active {
    opacity: 1;
    visibility: visible;
  }
}

.as-gallery-container__movie-wrap {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 5px;
  transition: ease .25s;

  &:hover {
    transform: scale(0.95);
    &:after {
      opacity: 1;
      background: rgba($as-c_main, 0.6);
      transform: scale(1);
    }
  }
}

.as-gallery-container__movie {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.as-gallery-preview__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.as-gallery-preview-slider {
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 800px;
  border-radius: 5px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.6);
  background: #242424;
  transform: translate(-50%, -50%);

  &:after {
    content: '';
    display: block;
    padding-bottom: 60%;
  }

  .swiper-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    overflow: hidden;
  }
}

.as-gallery-preview-slider__img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.as-gallery-preview-slider__btn_next,
.as-gallery-preview-slider__btn_prev {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 50%;
  margin: 10px;
  padding: 0;
  border: none;
  color: #fff;
  background: $as-c_main;
  transform: translate(0, -50%);
  @include circle-element(40px);

  @include respond-to(tablet) {
    @include circle-element(35px);
  }
}

.as-gallery-preview-slider__btn_next {
  left: 0;
}

.as-gallery-preview-slider__btn_prev {
  right: 0;
}