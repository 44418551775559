.as-map-wrapper {
  position: relative;
  width: 100%;
  margin: 25px 0;
  padding-bottom: 65%;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);

  @include respond-to(tablet){
    padding-bottom: 100%;
  }
}

#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.as-map-loader {
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: lighten($as-c_main, 10%);
  transition: ease .4s;

  &.as-active {
    opacity: 1;
    visibility: visible;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 25%;
      height: 100%;
      background: darken($as-c_main, 10%);
      animation: loader 2s ease infinite;
    }
  }
}

@keyframes loader {
  0% { left: 0; }
  50% { left: 100%; transform: translate(-100%, 0); }
  0% { left: 0; }
}

.as-map-info-window {
  display: flex;
  font-family: $as-font_family;

  @include respond-to(tablet){
    flex-wrap: wrap;
  }
}

.as-map-info-window__img-wrap {
  align-self: flex-start;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  width: 370px;

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  @include respond-to(tablet){
    width: 100%;
    margin-bottom: 10px;

    &:before {
      padding-bottom: 50%;
    }
  }
}

.as-map-info-window__img {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translate(-50%, 0);

  @include respond-to(tablet){
    height: auto;
    width: 100%;
  }
}

.as-map-info-window__main {
  padding-right: 20px;

  @include respond-to(tablet){
    padding-right: 0;
  }
}

.as-map-info-window__title {
  margin: 0;
}

.as-map-info-window__subtitle {
  margin: 0;
  font-size: 1.3em;
  font-weight: 500;
  color: #808080;
}

.as-map-info-window__content {
  margin: 10px 0;
  font-size: 1.1em;
}

.as-map-info-window__btn {
  display: inline-block;
  padding: 7px 20px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 1.3em;
  color: #fff;
  background: #A3A3A3;
}