.as-official-content-block__img-wrap {
  position: relative;
  overflow: hidden;
  float: right;
  margin-left: 35px;
  margin-bottom: 25px;
  border: 8px solid #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  @include circle-element(350px);

  @include respond-to(tablet) {
    float: none;
    margin: 20px auto;
    margin-bottom: 15px;
  }

  @include respond-to(handhelds) {
    border: 6px solid #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    @include circle-element(250px);
  }
}

.as-official-content-block__img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.as-official-content-menu {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #ddd;
}

.as-official-content-menu__btn {
  margin: 0 10px 10px;
  padding: 10px 25px;
  font-size: 1.1em;
  font-weight: 600;
}