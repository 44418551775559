.as-home-main {
  display: flex;
  margin: 50px -15px 70px;

  @include respond-to(small-screens){
    flex-wrap: wrap;
    margin: 40px 0 60px;
  }

  @include respond-to(small-tablet) {
    margin: 30px 0;
  }
}

.as-home-main__right {
  width: 75%;
  padding: 0 15px;

  @include respond-to(small-screens){
    width: 100%;
    margin-bottom: 30px;
  }
}

.as-home-main__left {
  display: flex;
  flex-wrap: wrap;
  width: 25%;
  padding: 0 15px;

  @include respond-to(small-screens){
    width: 100%;
    justify-content: center;
    flex-wrap: nowrap;
  }

  @include respond-to(small-tablet) {
    flex-wrap: wrap;
  }
}

.as-home-fb-page {
  overflow: hidden;
  display: block !important;
  margin-top: 9px;
  height: 350px;
  width: 100%;

  @include respond-to(small-screens){
    margin-top: 0;
    margin-right: 30px;
  }


  @include respond-to(small-tablet) {
    margin-top: 30px;
    margin-right: 0;
  }
}