.as-home-slider {
  overflow: hidden;
  position: relative;
  width: 100%;


  .swiper-pagination-bullet {
    opacity: 1;
    width: 14px;
    height: 14px;
    border: 2px solid $as-c_main;
    background: transparent;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    transition: ease .15s;
  }
  
  .swiper-pagination-bullet-active {
    background: $as-c_main;
  }
}

.as-home-slider-item {
  overflow: hidden;
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 1%, rgba(0,0,0,0.5) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.5) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 );
  }

  @include respond-to(small-screens) {
    height: 400px;
  }

  @include respond-to(small-tablet) {
    height: 300px;
  }
}

.as-home-slider__btn_next,
.as-home-slider__btn_prev {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: 50px;
  padding: 15px;
  text-align: center;
  border: none;
  background: rgba(36, 35, 35, 0.8);
  transition: ease .15s;

  @include respond-to(small-tablet) {
    display: none;
  }

  &:hover, &:focus {
    opacity: 1;
    background: rgba($as-c_main, 0.7);
  }
}

.as-home-slider__btn_next {
  left: 0;
  transform: translate(0, -50%);
}

.as-home-slider__btn_prev {
  right: 0;
  transform: translate(0, -50%);
}

.as-home-slider__btn__icon {
  color: #fff;
  font-size: 1.2em;
}

.as-home-slider-item__image {
  width: 100%;

  @include respond-to(small-screens) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translate(-50%, -50%);
  }
}

.as-home-slider-item__wrapper {
  position: absolute;
  height: auto;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0;

  @include respond-to(wide-screens) {
    top: auto;
    bottom: 0;
  }
}

.as-home-slider-item-box {
  width: 550px;
  margin-top: 45px;
  padding: 20px 30px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  @include clearfix;

  @include respond-to(wide-screens) {
    display: block;
    margin: 0 auto;
    padding: 15px 20px 40px;
  }

  @include respond-to(small-tablet) {
    width: 100%;
    padding: 20px 10px 50px;
    text-align: center;
    border-radius: 0;
  }
}

.as-home-slider-item-box__title {
  margin: 0;
  font-size: 3em;
  font-weight: 500;

  @include respond-to(wide-screens) {
    font-size: 2.5em;
  }

  @include respond-to(tablet) {
    font-size: 2em;
  }
}

.as-home-slider-item-box__content {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.1em;

  @include respond-to(small-tablet) {
    font-size: 1em;
  }
}

.as-home-slider-item-box__btn {
  float: left;
  padding: 6px 50px;
  font-size: 1.15em;

  @include respond-to(small-tablet) {
    float: none;
  }
}