.as-home-news-slider {
  z-index: 1;
  position: relative;
  background: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  
  @include respond-to(tablet) {
    .as-g__content-wrapper {
      padding: 0;
    }
  }
}

.as-home-news-slider__row {
  overflow: hidden;
  display: flex;
  margin: 0;

  @include respond-to(tablet) {
    flex-wrap: wrap;
  }
}

.as-home-news-slider__cell {
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);

  @include respond-to(tablet) {
    width: 100%;
    justify-content: center;
    padding: 10px;
  }
}

.as-home-news-slider__cell_middle {
  position: relative;
  flex-grow: 1;
  width: 1px;
  padding: 10px 0;
}

.as-home-news-slide__title {
  margin: 0;
  font-size: 2.4em;
  font-weight: 400;
  text-align: center;
  color: $as-c_main;
}

.as-news-slider {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.as-news-slider__btn {
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  margin: 0 2px;
  text-align: center;
  color: $as-c_main;
  background: transparent;
  -webkit-appearance: none;
  @include circle-element(35px);
  transition: ease .15s;

  &:hover {
    background: rgba($as-c_main, 0.15);
  }

  @include respond-to(small-tablet) {
    margin: 0 10px;
  }
}

.as-news-slider-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;

  @include respond-to(tablet) {
    padding: 10px;
  }
}

.as-news-slider-item__title {
  margin: 0;
  font-size: 1.3em;
  font-weight: 500;
}

.as-news-slider-item__date {
  font-weight: 400;
  margin-right: 5px;
  padding-right: 5px;
  font-size: 0.9em;
  color: #919191;
  border-right: 1px solid #919191;
}

.as-news-slider-item__description {
  margin: 5px 0 0;
}

.as-news-slider-item__btn {
  white-space: nowrap;
}