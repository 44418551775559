.as-main, .as-footer {
  transition: ease .2s;

  &.as-menu-open {
    filter: blur(5px);
  }
}

.as-menu {
  position: relative;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: stretch;

  @include respond-to(medium-screens){
    overflow: scroll;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    background: $as-bg_main;
    border-top: 5px solid $as-c_main;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
    transform: translate(-100%, 0);
    transition: $easeOutQuint .4s;

    &.as-active {
      transform: translate(0, 0);
    }
  }
}

.as-menu__close-btn {
  cursor: pointer;
  margin: 9px 0;
  font-size: 1.3em;
  color: $as-c_main;
  background: transparent;
  border: none;
}

.as-menu__item {
  position: relative;
  flex-shrink: 0;
  text-align: center;

  &:last-child {
    &:after {
      display: none;
    }
  }

  &:hover, &:focus {
    > .as-menu__item__link {
      color: #fff !important;
      background: $as-c_main !important;
    }

    > .as-menu__item__link_primary {
      background: $as-c_main_dark !important;
    }
  }

  @include respond-to(medium-screens){
    width: 100%;
    height: auto;
    padding: 15px 10px 0;
    text-align: right;
  }
}

.as-menu__item__link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 1.1em;
  padding: 18px 30px;
  color: #333;
  transition: ease .15s;
  @include skew-background;

  @include respond-to(wide-screens){ 
    font-size: 1em;
  }

  @include respond-to(medium-screens){
    display: inline-block;
    width: auto;
    padding: 8px 20px;    
  }

  &.as-active {
    color: #fff !important;
    background: $as-c_main !important;

    &:hover, &:focus {
      color: #fff;
      background: $as-c_main_dark;
    }
  }
}

.as-menu__item__link_primary {
  color: #fff !important;
  background: $as-c_main !important;
}

.as-menu__submenu {
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  min-width: 150px;
  background: rgba($as-c_main, 0.8);
  transform: translate(0, 20px);
  transition: ease .15s;
  transition-delay: 0.25s;

  .as-menu__item {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);

    &:last-child {
      border-width: 3px;
    }

    &:hover, &:focus {
      > .as-menu__item__link {
        background: rgba($as-c_main_dark, 0.6) !important;
      }
    }
  }

  .as-menu__item__link {
    font-size: 1.1em;
    padding: 15px 10px;
    color: #fff;
    transform: skew(0deg);

    > * {
      transform: skew(0deg);
    }
  }

  @include respond-to(medium-screens){
    position: static;
    max-width: 100%;
    max-height: 0px;
    background: transparent;
    transform: translate(0, 10px);
    transition: all ease .2s, max-height ease .4s;
    
    .as-menu__item {
      padding: 10px 0 0;

      &:hover, &:focus {
        > .as-menu__item__link {
          background: $as-c_main !important;
        }
      }
    }

    .as-menu__item__link {
      color: #333;
      padding: 10px;
      @include skew-background;
    }
  }
}

.as-menu__submenu .as-menu__submenu {
  top: 0;
  right: 100%;
  border-bottom: none;
}

.as-menu__item:hover > .as-menu__submenu, 
.as-menu__item > .as-menu__submenu.as-active {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate(0, 0);

  @include respond-to(medium-screens){
    transition-delay: 0s;
    transition: all ease .4s, max-height ease .5s;
  }
}

.as-menu__item:hover > .as-menu__submenu:not(.as-active) {
  @include respond-to(medium-screens){
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    transform: translate(0, 0);
  }
}

.as-menu-active-btn {
  display: none;
  float: left;
  text-align: center;
  font-size: 1em;
  color: $as-c_main_dark;
  @include circle-element(28px);
  
  @include respond-to(medium-screens){
    display: block;
  }

  &.as-active {
    i {
      transform: rotate(180deg);
    }
  }

  i {
    display: block;
    transition: ease .2s;
  }
}

.as-menu-mask {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: ease .2s;

  &.as-active {
    opacity: 1;
    visibility: visible;
  }
}