.as-course-content-block__img {
  display: block;
  width: 100%;
  max-height: 500px;
}

.as-course-content-block__main {
  position: relative;
  padding-top: 20px;
}

.as-course-content-block__top {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  > * {
    position: relative;
    left: -10px;
  }
}

.as-course-content-block__bottom {
  text-align: left;
}