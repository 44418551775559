.datepicker {
  z-index: 9999;
  position: absolute;
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  margin-top: 10px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);

  .table-condensed {
    width: 100%;
  }

  .year {
    border-color: transparent !important;
    transition: ease .15s;

    &.active {
      color: #fff;
      background: $as-c_main !important;
    }
  }

  .month {
    border-color: transparent !important;
    transition: ease .15s;
    
    &.active {
      color: #fff;
      background: $as-c_main !important;
    }
  }

  .day {
    padding: 4% 0;
    border-color: transparent !important;
    transition: ease .15s;

    &.active {
      color: #fff;
      background: $as-c_main !important;
    }
    
    &.today {
      color: $as-c_main_dark;
      background-color: rgba($as-c_main, 0.2) !important;

      &:hover, &:focus {
        color: $as-c_main_dark !important;
        background-color: rgba($as-c_main, 0.4) !important;
      }
    }
  }
}