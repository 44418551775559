.as-home-artical-wrap {
  background: #F3F3F3;
}

.as-home-artical {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 40px 0;

  @include respond-to(small-handhelds){
    padding: 30px 0;
  }
}

.as-home-artical__cell {
  flex-grow: 1;
  width: 100%;
  padding: 0 15px;

  @include respond-to(tablet){
    padding: 0;
    margin-bottom: 15px;
  }
}

.as-home-artical__title {
  margin: 0 0 20px;
  font-size: 2.4em;
  font-weight: 400;
  color: $as-c_main;
}

.as-home-artical__description {
  margin-bottom: 20px;
  font-size: 1em;
}

.as-home-artical__btn {
  font-size: 1.2em;
  font-weight: 400;
  color: $as-c_main;
  text-decoration: underline;

  &:hover {
    color: darken($as-c_main, 10%);
  }
}