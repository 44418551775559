.as-subject-main-content {
  display: flex;
  align-items: flex-start;
  padding-bottom: 30px;
  border-bottom: 3px solid #ddd;

  @include respond-to(small-screens) {
    flex-wrap: wrap;
  }
}

.as-subject-main-content__img {
  max-width: 500px;
  width: 100%;
  height: auto;
  margin-left: 30px;

  @include respond-to(small-screens) {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.as-subject-sub-content {
  padding: 30px 0;
}

.as-subject-sub-content__title {
  margin: 0;
  color: #5E5E5E;

  @include respond-to(medium-screens) {
    font-size: 1.8em;
  }
}

.as-subject-sub-content__content {
  margin-top: 20px;
  font-size: 0.95em;
}

.as-subject-menu {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding: 30px 0;
  border-top: 1px solid #ddd;
}

.as-subject-menu__item {
  width: 50%;
  padding: 10px;
  border-radius: 3px;
  color: #333;
  transition: ease .15s;
  
  &:hover {
    background: rgba($as-c_main, 0.15);
  }

  @include respond-to(tablet) {
    width: 100%;
  }
}

.as-subject-caregory {
  display: flex;
  align-items: flex-start;
}

.as-subject-caregory__img-wrap {
  overflow: hidden;
  position: relative;
  width: 150px;
  border-radius: 3px;
  
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.as-subject-caregory__img {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translate(-50%, 0);
}

.as-subject-caregory__content {
  width: 100%;
  padding-right: 20px;
}

.as-subject-caregory__title {
  margin: 0;
  color: $as-c_main;
}

.as-subject-caregory__description {
  font-size: 0.9em;
  color: #5F5F5F;
}