.as-subjects-item {
  display: block;
  margin-bottom: 20px;
  border-bottom: 3px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
}

.as-subjects-item__title {
  margin: 0;
  color: #5E5E5E;

  @include respond-to(medium-screens) {
    font-size: 1.8em;
  }
}

.as-subjects-item__subtitle {
  margin: 0;
  font-size: 1.3em;
  font-weight: 500;
  color: #919191;

  @include respond-to(medium-screens) {
    font-size: 1.1em;
  }
}

.as-subjects-item__description {
  margin: 20px 0;
  line-height: 1.4em;
}

.as-subjects-item-menu {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding: 20px 0;
  border-top: 1px solid #ddd;
}

.as-subjects-item-menu__item {
  width: 50%;
  padding: 10px;
  border-radius: 3px;
  color: #333;
  transition: ease .15s;
  
  &:hover {
    background: rgba($as-c_main, 0.15);
  }

  @include respond-to(tablet) {
    width: 100%;
  }
}

.as-subjects-item-caregory {
  display: flex;
  align-items: flex-start;
}

.as-subjects-item-caregory__img-wrap {
  overflow: hidden;
  position: relative;
  width: 150px;
  border-radius: 3px;
  
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.as-subjects-item-caregory__img {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translate(-50%, 0);
}

.as-subjects-item-caregory__content {
  width: 100%;
  padding-right: 20px;
}

.as-subjects-item-caregory__title {
  margin: 0;
  color: $as-c_main;
}

.as-subjects-item-caregory__description {
  font-size: 0.9em;
  color: #5F5F5F;
}