@mixin respond-to($media) {
	@if $media == small-handhelds {
		@media (max-width: 345px) { 
			@content; 
		}
	}
	@else if $media == handhelds {
		@media (max-width: 425px) { 
			@content;
		}
	}
	@else if $media == small-tablet {
		@media (max-width: 600px) { 
			@content; 
		}
	}
	@else if $media == tablet {
		@media (max-width: 800px) { 
			@content; 
		}
	}
	@else if $media == small-screens {
		@media (max-width: 1000px) { 
			@content; 
		}
	}
	@else if $media == medium-screens {
		@media (max-width: 1150px) { 
			@content; 
		}
	}
	@else if $media == wide-screens {
		@media (max-width: 1280px) { 
			@content; 
		}
	}
	@else if $media == medium-wide-screens {
		@media (max-width: 1380px) { 
			@content; 
		}
	}
	@else if $media == large-wide-screens {
		@media (max-width: 1560px) { 
			@content; 
		}
	}
	@else if $media == print {
		@media print { 
			@content; 
		}
	}
}