.as-form-container__row {
  margin: 0 -10px;
  @include respond-to(tablet) {
    flex-wrap: wrap;
    margin: 0 auto;
  }
}

.as-form-container__item {
  position: relative;
  margin-bottom: 15px;
}

.as-form-container__cell {
  @include respond-to(tablet) {
    margin: 0 0 20px;
  }
}