.as-footer {
  position: relative;
  width: 100%;
  background: #fff;
  border-bottom: 8px solid $as-c_main;
  box-shadow: 0 -2px 6px 0 rgba(0,0,0,.1);
}

.as-footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  &:first-child {
    border-top: none;
  }
}

.as-footer-cell {
  align-self: center;

  @include respond-to(tablet){
    width: 100%;
    margin: 5px 0;
    text-align: center;
  }
}

.as-footer-copyrights {
  direction: ltr;
  width: 100%;
  text-align: center;
  font-size: 1em;

  > * {
    vertical-align: middle;
  }

  @include respond-to(tablet) {
    text-align: center;
  }
}

.as-footer-copyrights__symbol {
  font-weight: 600;
}

.as-footer-copyrights__logo {
  display: inline-block !important;
  width: 60px;
}

.as-footer-logos-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.as-footer-logos-menu-item {
  max-width: 165px;
}

.as-footer-logos-menu-item__img {
  width: 100%;
}