.as-breadcrumbs-nav {
  padding: 20px 0;
  background: rgba($as-c_main, 0.15);
}

.as-breadcrumbs-nav__item  {
  font-size: 0.9em;
  color: #8A8A8A;

  &:hover {
    text-decoration: underline;
  }

  &:after {
    content: '>';
    display: inline-block;
    margin: 0 5px;
  }
}

.as-breadcrumbs-nav__item_current {
  @extend .as-breadcrumbs-nav__item;
  font-weight: 700;
  color: $as-c_main;

  &:hover {
    text-decoration: none;
  }

  &:after {
    display: none;
  }
}