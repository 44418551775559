.as-socials-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 20px;

  @include respond-to(medium-screens) {
    display: none;
  }
}

.as-socials-menu__item {
  margin: 5px 0;
  text-align: center;
  font-size: 1.3em;
  color: #fff;
  background: rgba($as-c_main, 0.9);
  @include circle-element(40px);
  transition: ease .15s;

  &:hover, &:focus {
    color: #fff;
    background: rgba(darken($as-c_main, 10%), 0.9);
  }
}