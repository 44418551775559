.as-page-heading {
  margin-bottom: 20px;
}

.as-page-heading__title {
  margin: 0;
  font-size: 3em;
  font-weight: 500;

  @include respond-to(medium-screens) {
    font-size: 2.2em;
  }
}

.as-page-heading__subtitle {
  margin: 0;
  font-size: 1.3em;
  font-weight: 400;
  color: #6f6f6f;

  @include respond-to(medium-screens) {
    font-size: 1.2em;
  }
}