.as-officials-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.as-officials-list__item {
  width: 50%;
  padding: 10px;

  @include respond-to(tablet) {
    width: 100%;
  }
}

.as-officials-list-item {
  overflow: hidden;
  display: block;
  width: 100%;
  color: #333;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,.15);
}

.as-officials-list-item__main {
  display: flex;
  align-items: center;

  @include respond-to(small-tablet) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}

.as-officials-list-item__cell {
  width: 50%;

  @include respond-to(small-tablet) {
    width: 100%;
  }
}

.as-officials-list-item__img-wrap {
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.as-officials-list-item__img {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translate(-50%, 0);
}

.as-officials-list-item__content {
  padding: 15px;
  text-align: center;
}

.as-officials-list-item__title {
  margin: 0;
  font-size: 2em;
  font-weight: 400;
  color: $as-c_main;

  @include respond-to(small-tablet) {
    font-size: 1.8em;
  }
}

.as-officials-list-item__subtitle {
  margin: 0;
  font-size: 1.2em;
  font-weight: 400;
  color: #A3A3A3;

  @include respond-to(small-tablet) {
    font-size: 1em;
  }
}

.as-officials-list-item__description {
  margin: 15px 0;
}

.as-officials-list-item__menu {
  margin-top: 20px;
}

.as-officials-list-item__btn {
  font-size: 1.6em;
  color: $as-c_main;
  margin: 0 10px;
}